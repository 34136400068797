import { Grid, makeStyles, Typography } from "@material-ui/core"
import React from "react"
import CustomButton from "../../components/CustomButton"
import clubSport from "../../../images/club_sport.svg"
import DrawerSide from "../../components/DrawerSide"
import { Link } from "gatsby"
import { useDispatch } from "react-redux"
import { actions } from "../../../redux/slices/clubSlice"
import Head from "../../components/Head"

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F9FAFC",
    width: "calc(100% - 40px)",
    borderRadius: "10px 10px 0px 0px",
    height: "100vh",
    paddingTop: "10px",
    paddingLeft: "40px",
    marginTop: "100px",
    marginLeft: "26px",
    fontFamily: "DM Sans",
  },
  welcomeText: {
    fontSize: "28px",
    marginBottom: "40px",
  },
  subText: {
    color: "#0A3133",
    fontFamily: "DM Sans",
    fontStyle: "normal",
    fontSize: "14px",
    marginBottom: "100px",
    fontWeight: "400",
  },
  boxRoot: {
    display: "flex",
    paddingTop: "20px",
  },
  clubSportImg: {
    width: "300px",
    height: "300px",
    marginTop: "20px",
  },
}))

function Welcome() {
  const classes = useStyles()

  const dispatch = useDispatch()

  const handleClick = () => {
    localStorage.removeItem("persist:root")
    dispatch(actions.removeInfo())
  }

  return (
    <>
      <Head pageTitle="Welcome" />
      <DrawerSide>
        <div className={classes.root}>
          <Grid direction="row" className={classes.boxRoot}>
            <Grid container direction="column">
              <Typography className={classes.welcomeText}>Welcome!</Typography>
              <Typography className={classes.subText} noWrap={true}>
                Complete these 3 easy steps to create an account. If you don’t
                have time
                <br /> to fill in all information than you can skip them and add
                it later.
              </Typography>
              <Link
                to="/venue/sign-up?step=1"
                style={{ textDecoration: "none" }}
              >
                <CustomButton
                  theme={"manager"}
                  text="Start"
                  handleOnClick={handleClick}
                />
              </Link>
            </Grid>
          </Grid>
        </div>
      </DrawerSide>
    </>
  )
}

export default Welcome
